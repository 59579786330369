import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import conta from "../images/12.webp";


function Contact () {
    return(
        <>
            <Navbar/>
            <Hero
            cName="hero-about"
            heroImg={conta}
            title="Contacto "
            text=""
            buttonText=""
            url="/"
            btnClass=""
            />       
            <ContactForm/> 
            <Footer/>
        </>
    )
}

export default Contact;