import "./TripBoxStyles.css";
import TripBoxData from "./TripBoxData";
import trip1 from "../images/11.webp";
import trip2 from "../images/13.webp";
import trip3 from "../images/14.webp";



function TripBox(){
    return(
        <div className="trip">
            <h1>Destinos recientes</h1>
            <p>Lugares únicos con personas únicas</p>
            <div className="tripcard-afu">
                <TripBoxData
                    image={trip1}
                    heading= "Villa Florida"
                    text= "Rodeada de exuberante vegetación, incluyendo bosques, ríos y arroyos. Esta riqueza natural proporciona un hermoso paisaje y ofrece oportunidades para actividades al aire libre como la pesca, el senderismo y los paseos en bote, especial para reunirse y disfrutar de actividades."
                />
                <TripBoxData
                    image={trip2}
                    heading= "Filadelfia"
                    text= "Es reconocida por su orden y limpieza, así como por la preservación de su cultura y tradiciones menonitas. Los visitantes pueden aprender sobre la cultura menonita visitando el Museo Jakob Unger o participando en tours guiados por las colonias menonitas cercanas."
                />
                <TripBoxData
                    image={trip3}
                    heading= "Aregua"
                    text= "Famosa por su producción de cerámica tradicional, la expo frutilla y sus calles empedradas albergan talleres de artesanos y galerías de arte donde podrás encontrar hermosas piezas de cerámica y otros objetos decorativos para disfrutar de su encanto histórico y descubrir la cultura local."
                />
            </div>
        </div>
    )
}

export default TripBox;