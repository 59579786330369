import fot1 from "../images/1.webp";
import fot2 from "../images/2.webp";
import fot3 from "../images/3.webp";
import fot4 from "../images/4.webp";
import DestinationData from "./DestinationData";
import "./DestinationStyle.css"


const Destination = () =>{
    return(
        <div className="destination">
            <h1>Destinos Populares</h1>
            <p>Recorridos que te brindan la oportunidad de descubrir aún más el Paraguay</p>
            <DestinationData
            className="first-des"
                heading= "San Bernardino"
                text= "Es una hermosa ciudad ubicada en el departamento de Cordillera, en Paraguay, siendo un popular destino turístico, especialmente durante los meses de verano debido a su agradable clima y sus playas en la costa del Lago Ypacaraí."
                img1= {fot1} 
                img2= {fot2}
            />
            <DestinationData
            className="first-des-reverse"
                heading= "Ruinas Jesuiticas y Encarnación"
                text= "Un lugar único e histórico para compartir en familia y amigos a pocos kilómetros de Encarnación siendo está una ciudad con frontera a Argentina con una hermosa costanera y playas icónicas dentro del país."
                img1= {fot3} 
                img2= {fot4}
            />
        </div>
    )
}

export default Destination;