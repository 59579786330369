import Destination from '../components/Destination';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import TripBox from '../components/TripBox';
import img12 from "../images/17.webp";



function Home () {
    return(
        <>
            <Navbar/>
            <Hero
            cName="hero"
            heroImg={img12}
            title="Alquiler de Buses"
            title2="Turismo - Corporativo"
            text="Contamos con buses que transportan hasta 44 pasajeros a todo confort con servicios de corta y larga distancia dentro del Paraguay 🇵🇾"
            buttonText="Solicitar presupuesto"
            url="https://api.whatsapp.com/send?phone=595994607757&text=Hola!%20Me%20gustar%C3%ADa%20alquilar%20un%20bus%20%F0%9F%9A%8D."
            btnClass="show"
            />
            <Destination/>
            <TripBox/>
            <Footer/>
         </>
    )
}

export default Home;