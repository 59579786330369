import "./FooterStyle.css";
import logo from "../images/logo.png"


const Footer = () =>{
    return(
        <div className="footer">
            <div className="top">
                <div className="logo-text">
                    <h1 className="navbar-logo2">busses</h1>
                    <img src={logo} alt='' className="logo"/>
                </div>
                <div>
                    <a href="/">
                        <i className="fa-brands fa-facebook-square"></i>
                    </a>
                    <a href="/">
                        <i className="fa-brands fa-instagram-square"></i>
                    </a>
                    <a href="/">
                        <i className="fa-brands fa-whatsapp-square"></i>
                    </a>
                </div>
            </div>
                <div className="bottom">
                    <div>
                        <h4>Proyecto</h4>
                        <a href="/">Cambios</a>
                        <a href="/">Horarios</a>
                    </div>
                    <div>
                        <h4>Comunidad</h4>
                        <a href="/">Facebook Group (soon)</a>
                        <a href="/">Tik Tok (soon)</a>
                    </div>
                    <div>
                        <h4>Ayuda</h4>
                        <a href="/">Soporte</a>
                        <a href="/">Contacto</a>
                        <a href="/">Correo</a>
                    </div>
                </div> 
                <div className="foo">
                    <h1>All rights reserved bussespy. Powered by B.L 2023.</h1>
                </div>
        </div>
    )
}

export default Footer;