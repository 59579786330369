import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import TripBox from '../components/TripBox';
import night from "../images/4.webp";


function Service () {
    return(
        <>
            <Navbar/>
            <Hero
            cName="hero-about"
            heroImg={night}
            title="Turismo "
            text=""
            buttonText=""
            url="/"
            btnClass=""
            />     
            <TripBox/>
            <Footer/>   
        </>
    )
}

export default Service;