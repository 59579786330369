import "./AboutUsStyle.css";

function AboutUs (){
    return(
        <div className="about-container">
            <h1>Nuestra Misión</h1>
            <p>Proporcionar servicios de alquiler de buses confiables, seguros y cómodos, brindando a nuestros clientes una solución de transporte eficiente y conveniente. Nos comprometemos a ofrecer un servicio excepcional, cumpliendo con altos estándares de calidad, puntualidad y atención al cliente, para satisfacer las necesidades de movilidad de individuos y grupos en Paraguay.</p>
            <h1>Nuestra Visión</h1>
            <p>Nos esforzamos por convertirnos en la empresa líder en el alquiler de buses en Paraguay, reconocida por nuestra excelencia operativa, confiabilidad y compromiso con la satisfacción del cliente. Buscamos expandir nuestra flota de vehículos y fortalecer nuestras alianzas estratégicas para ofrecer una amplia gama de opciones de transporte a nuestros clientes. Además, nos comprometemos a implementar prácticas sostenibles y respetuosas con el medio ambiente en todas nuestras operaciones, contribuyendo así a la preservación de nuestro entorno natural.</p>
        </div>
    )
}

export default AboutUs;