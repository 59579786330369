export const MenuItems = [
    {
        tittle : "Inicio",
        url : "/",
        cName : "Nav-Links",
        icon : "fa-solid fa-caravan",
    },
    {
        tittle : "Sobre nosotros",
        url : "/about",
        cName : "Nav-Links",
        icon : "fa-solid fa-lightbulb",
    },
    {
        tittle : "Servicios",
        url : "/service",
        cName : "Nav-Links",
        icon : "fa-solid fa-bus-simple",
    },
    {
        tittle : "Contacto",
        url : "/contact",
        cName : "Nav-Links",
        icon : "fa-solid fa-address-book",
    },

]