import AboutUs from '../components/AboutUs';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import night from "../images/16.webp";

function About () {
    return(
        <>
            <Navbar/>
            <Hero
            cName="hero-about"
            heroImg={night}
            title="Sobre Nosotros"
            text=""
            buttonText=""
            url="/"
            btnClass=""
            />      
            <AboutUs/>
            <Footer/>  
        </>
    )
}

export default About;